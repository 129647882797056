@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');

@font-face {
    font-family: 'Satoshi';
    src: url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff2") format('woff2'),
        url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff") format('woff'),
        url("./assets/Fonts/WEB/fonts/Satoshi-Bold.ttf") format('truetype'),
}


.ant-form-item {
	margin-bottom: 1.25rem !important;
}

.ant-form-item-label{
    padding: 0 0 .25rem !important;
}

.ant-form-item-label label{ 
    font-family: "DM Sans" !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    line-height: 24px !important;  
}
 
.ant-select-selector { 
	width: 100% !important;
	align-items: center !important;
	border-radius: 6px !important;
	border: 1px solid #cacaca !important;
}

.ant-select { 
	min-height: 3.125rem !important;
	width: 100% !important;
	align-items: center !important;  
	border-radius: 6px !important;
	border:1px solid #212121 !important
}

.ant-select-multiple.ant-select-lg .ant-select-selector{
	width: 100% !important;
	align-items: center !important;
	border-radius: 6px !important;
	border: 1px solid #cacaca !important;
}
.ant-select-multiple.ant-select-lg .ant-select-selector:focus{
	width: 100% !important;
	align-items: center !important;
	border-radius: 6px !important;
	border: 1px solid #cacaca !important;
}
 
.banner-select .ant-select-selector { 
	width: 100% !important;
	align-items: center !important;
	border-radius: 30px !important;
	border: 1px solid #D4D4D8 !important;
}

.ant-select-selection-item{
	display: flex;
}

 

.select-borderless .ant-select-selector {
	height: 52px !important;
	width: 100% !important;
	align-items: center !important;
	border-radius: 6px !important;
	border: 1px solid #212121 !important;
}

.select-borderless .ant-select {
	height: 52px !important;
	width: 100% !important;
	align-items: center !important;
	border-radius: 6px !important;
	border: 1px solid #212121 !important;
}


.ant-form-item-extra{
	color:var(--darkBlue) !important;
	font-weight: 400 !important;
	font-size: 14px !important; 
    font-family: "DM Sans" !important; 
    line-height: 20px !important; 
}

.ant-select .ant-select-arrow{
    /* color: #212121 !important; */
    font-size: 16px !important;
}

.ant-input-affix-wrapper{
	border-radius: 6px;
	width:100%;
	height: 42px;  
	border: 1px solid #cacaca !important;
	font-size: 16px; 
	background: #fff !important;
	font-weight: 400 !important;
	color:#94A3B8 !important;
	font-family: "Lexend", sans-serif; 
}


.ant-tabs-nav-wrap {
	padding: 0px 0px !important;
	background-color: inherit !important;
}
.ant-tabs-nav-list {
	width: 30%;
	display: flex;
	justify-content: space-between;
}


.accrdion-card{
	border: 1px solid rgba(31, 45, 61, .125);
	box-shadow: 0 1px 2px 0 rgba(31, 45, 61, .07);
	border-radius: 4px;
}

.accrdion-service-card{
	border: 2px solid #E5E7EB; 
}


.ant-steps .ant-steps-item-process .ant-steps-item-icon >.ant-steps-icon{
	color:#212121
}

.custom-step {
	display: flex;
	flex-direction: column;
	align-items: center;
}
  
.custom-step-title {
	margin-top: 3px;
	font-size: 14px;
	text-align: center;
	line-height: 20px;
	font-weight: 500;
	font-family: "Lexend", sans-serif; 
}

.ant-steps.ant-steps-dot .ant-steps-item-tail{ 
	margin-top: 8.5px; 
}
.ant-steps .ant-steps-item-finish .ant-steps-item-icon >.ant-steps-icon{
	color: #14B04C;
}

.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after{
	background: #14B04C;
}

.ant-form-item .ant-form-item-label >label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
	display: none !important;
}

.ant-spin-dot-item {
	background-color: white !important;
}
.sms-spin .ant-spin-dot-item {
	background-color: #14B04C !important;
}
.ant-input-affix-wrapper >input.ant-input{
	color:#212121;
}
 
.ant-picker .ant-picker-input >input::placeholder{
	color:#212121 !important;
}
.ant-steps.ant-steps-vertical {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ant-steps.ant-steps-horizontal {

}
.ant-menu-light.ant-menu-root.ant-menu-inline{
	border-inline-end:none;
}

 
.mobile-drawer .ant-drawer-header{
	display: none !important;
}

 
.ant-modal-footer {
	display: none!important;
}

.ant-modal-content {
	padding: 0 !important;
	z-index: 1000;
	
}

.ant-modal-body {
	padding: 20px 17px !important;
	
}

.ant-modal-close-x {
	color: #fff !important;
	font-weight: 500;
}
.ant-modal .ant-modal-title {
	color: #fff !important;
	padding: 20px 17px;
	font-family: "Roboto", sans-serif;
    font-size: 24px;
	font-weight: 400; 
	background-color: var(--mainBlack);
}
.ant-modal .ant-modal-close {
	top:19px !important;
}

.filter-modal .ant-modal-title {
	display: none !important;
}
 
/* Ant tabs */
.activity-tab-wrap .ant-tabs-nav-wrap {
	padding: 0px  !important;
	background-color: inherit !important;
}
.activity-tab .ant-tabs-nav-list {
	width: 50%;
	display: flex;
	justify-content: space-between;
}
.activity-bar .ant-tabs-ink-bar {
	width: 105px !important;
	height: 2px !important;
	border-radius: 8px !important;
	background-color: var(--lightGold)!important;
	display: flex;
	justify-content: space-between ;
	align-items: center ; 
}
 
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
	color: var(--lightRed)!important;
	font-weight: 600;
} 
.ant-tabs-tab {
	color: var(--light-type-high, #070A13);
	text-align: center; 
	font-family: "Public Sans";
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.25rem; 
	
}
.ant-tabs {
	width: 100% !important;
}

 
.prod-view-tab .ant-tabs-nav-list {
	width: 20%;
	display: flex;
	justify-content: space-between;
}

 
 
/* ANTD TABLE */

.ant-table-wrapper {
	border-radius: 0rem !important;
	background: transparent !important;
	box-shadow: 0px !important;
	border-left: 1px solid rgba(224, 224, 224, 0.00) !important;
}

.ant-table-thead .ant-table-cell {
	background: #F2F4F7 !important;
	color: var(--text-black, #222) !important;
	font-family: "DM Sans" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 500 !important;
	line-height: normal !important;
	text-transform: capitalize !important;
	border-start-end-radius: 0px !important;
	border-start-start-radius: 0px !important;
}

.ant-table-cell {
	color: var(--Gray-900, #101828) !important;
	font-family: "DM Sans" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 1.5rem !important;
}

.ant-table-footer {
	padding: 0px !important;
	background: transparent !important;
}

.editable_table .ant-table-cell {
	padding: .75rem !important;
}

.ant-menu-dark{
	background-color: #f4f5f7 !important; 
}
.ant-menu-item,
.ant-menu-submenu-title{
	color: #222222 !important;
	font-family: "Source Sans 3", sans-serif !important;
	font-size: 16px !important;
}
 
.ant-menu.ant-menu-sub.ant-menu-inline{
	/* background-color: var(--darkGreen) !important; */
	background-color: #f4f5f7 !important;
}
.ant-radio-wrapper span.ant-radio+* {
	color: #222222 !important; 
    font-family: "Source Sans 3", sans-serif !important;
    font-size: 16px !important; 
    font-weight: 400 !important; 
}

.check-permission .ant-checkbox+span {
    color: #171A1F !important; 
    font-family: "DM Sans" !important;
    font-size: 1rem !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 140% !important; 
}
 
/* @media (max-width: 768px) {
	.recharts-legend-wrapper {
	 display: none !important;
	}
  } */
.ant-menu-dark .ant-menu-item-selected{
	color: #fff !important;
}

.ant-menu-sub {
	padding: 0 .75rem !important;
}

.pie-legend .recharts-legend-wrapper{
	bottom: 40px !important;
}

/* ANTD DESCRIPTION */
.ant-descriptions-title {
	color: var(--Gray-900, #101828) !important; 
	font-family: "DM Sans" !important;
	font-size: 1.5rem !important;
	font-style: normal !important;
	font-weight: 700 !important;
	line-height: 1.5rem !important;
}

.ant-descriptions-item-label {
	min-width: 10.5rem;
	color: var(--Black, #000) !important;
	font-family: "DM Sans" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 1.25rem !important;
}

.ant-descriptions-item-content {
	color: var(--Gray-500, #667085) !important;
	font-family: "DM Sans" !important;
	font-size: 1rem !important;
	font-style: normal !important;
	font-weight: 400 !important;
	line-height: 1.25rem !important;
}




/* For WebKit browsers (Chrome, Safari) */
.custom-scrollbar::-webkit-scrollbar {
	width: 8px;  /* Width of the scrollbar */
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
	background-color: #a9a9a9;  /* Scrollbar color */
	border-radius: 4px;     /* Rounded corners for scrollbar */
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
	background: #f4f5f7;   /* Background color of the scrollbar track */
  }
  
  /* For Firefox */
  .custom-scrollbar {
	scrollbar-width: thin;      /* Scrollbar width */
	scrollbar-color: #a9a9a9 #f4f5f7;  /* Scrollbar color and track color */
  }
  
  .ant-breadcrumb{
	font-size: 16px;
  }

  .search-input {
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 31.25rem;  
	padding: .8125rem .875rem;
	gap: 0.5rem;
	border-radius: 3.125rem;
	background: #FFF;
	box-shadow: 0px 0px 40px 0px rgba(26, 28, 40, 0.07);
	color: #626364;
	font-family: "DM Sans";
	font-size: 1.125rem;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5rem;
}

.blu_spin .ant-spin-dot-item {
	background-color: #1677ff !important;
}