@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
 

@font-face {
    font-family: 'Satoshi';
    src: url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff2") format('woff2'),
        url("./assets/Fonts/WEB/fonts/Satoshi-Bold.woff") format('woff'),
        url("./assets/Fonts/WEB/fonts/Satoshi-Bold.ttf") format('truetype'),
}


:root {
	--primary-navy-blue: #3F51F4;
    --primary-emerald-green: #2F855A;
    --primary-slate-grey: #4A5568;

    /* Secondary Colors */
    --secondary-gold: #D69E2E;
    --secondary-light-grey: #A0AEC0;
    --soft-grey: #E0E0E0;

    /* Neutral Colors */
    --neutral-white: #F9F9F9;

    /* Accent Colors */
    --accent-royal-blue: #3B82F6;
    --accent-soft-green: #68D391;
    --accent-warm-yellow: #F6E05E;

    --mainBlack: #10101E; 
    --lightGold: #FFB600;
	--lightRed:#DE612B;
    --blu:#1677FF;
	font-family: 'DM Sans', sans-serif !important;
}
body {
    background-color: var(--neutral-white);
    color: var(--primary-slate-grey);
	font-family: 'DM Sans', sans-serif !important;
}

html {
	background-color: var(--mainBg) !important;
	overflow-y: hidden;
	font-family: 'DM Sans', sans-serif !important;
}

.react-tel-input .form-control {
	height: 100% !important;
	width: 100% !important;
	border: none !important;
	outline: none !important;
	padding-left: 75px !important;
}

.flag-dropdown {
	padding: 10px !important;
}

.verification_complete {
	display: flex;
	width: 31.25rem;
	padding: 2.5rem;
	flex-direction: column;
	align-items: center;
	border-radius: 1.5rem;
	background: #ECECEC;
}


.login-pic {
	background-image: url('./assets/img/img1.png');
	/* height: 100%; */
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}
 

.login-box {
	background-color: rgba(0, 0, 0, 0.4);
	border-radius: 5px;
	padding: 70px 40px 50px;
	max-width: 480px;
	width: 100%;
}
.white_card {
    display: flex;
    padding: 1rem 2.5rem;
    align-self: stretch;
    border-radius: 1.25rem;
    background: #FFF;
}

@media (max-width: 600px) {
	.white_card {
        padding: .5rem;
	}
}

.account_card_alt {
    display: flex;
    flex-direction: column;
    gap: .75rem;
    width: 100%;
    min-height: 5.3125rem;
    height: auto;
    padding: 1.25rem 1.5rem;
    align-items: flex-start;
    border-radius: 1.5rem;
    border: 1px solid var(--Tile-stroke, #E2E9EE);
    background: #FFF;
}